import React from "react"
import { Link } from "gatsby"

import "./Blogs.css"

import blogImage from "../../static/images/lucas-unsplash.jpg"

const blog = ({ blogs, isAll = false }) => {
  return (
    <div className="container">
      <div className="blog">
        {blogs.map(({ node }, i) => (
          <Link to={`/blog/${node.slug}/`} key={`blog-${node.slug}`}>
            <div>
              <img src={blogImage} alt={node.title} />
              <p>{node.title}</p>
              <span>8 min read</span>
              <span id="swatisinha">{node.author.name}</span>
            </div>
          </Link>
        ))}
      </div>
      {isAll && (
        <div className="viewButton">
          <a href="/blog">
            <button className="viewAll">View All</button>
          </a>
        </div>
      )}
    </div>
  )
}

export default blog
