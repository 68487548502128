import React from "react"
import Img from "gatsby-image"

import "./Head.css"

const Head = ({ data }) => {
  return (
    <div className="head">
      <div className="text">
        <h1>Hiring Redefined</h1>
        <h2>The Right Role,</h2>
        <h2>The Right Person,</h2>
        <h2>The Right Fit</h2>
        <button className="But1">Get started</button>
      </div>
      <div className="images">
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>
    </div>
  )
}

export default Head
