import React from "react"

import Header from "../components/Header/Header.js"
import NavBar from "../components/Navbar/Navbar"
import Head from "../components/Head/Head.js"
import SearchBar from "../components/SearchBar/SearchBar.js"
import Team from "../components/Team/Team.js"
import Title from "../components/Title/Title.js"
import Talent from "../components/Talent/Talent.js"
import Work from "../components/Work/Work.js"
import Companies from "../components/Companies/Companies.js"
import JobBoard from "../components/JobBoard/JobBoard.js"
import Blogs from "../components/Blogs/Blogs.js"
import Hiring from "../components/Hiring/Hiring.js"
import Footer from "../components/Footer/Footer"
import Carousel from "../components/Carousel/Carousel"

import "./index.css"

const IndexPage = props => {
  const data = props.data
  return (
    <div className="App">
      <Header canonicalUrl="https://savannahr.com/" />
      <NavBar />
      <Head data={data} />
      <SearchBar />
      <Team />
      <Title
        title="TALENTS"
        subTitle="The choice is yours, we’ve got you covered"
      />
      <Talent />
      <Title title="OUR SCOPE OF WORK" subTitle="Why Savannah?" />
      <Work />
      <Title title="OUR PRIDE" subTitle="Companies We Worked With" />
      <Companies />
      <Title title="WORK" subTitle="Introducing our job board - HireXL" />
      <JobBoard />
      <Title title="TESTIMONIALS" subTitle="What Our Clients Say" />
      <Carousel />
      <Title title="SOME WORD FOR BLOG" subTitle="Our Blogs" />
      <Blogs blogs={data.allWordpressPost.edges} isAll />
      <Hiring />
      <Footer />
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allWordpressPost(limit: 3) {
      edges {
        node {
          title
          excerpt
          slug
          date(fromNow: true)
          author {
            id
            slug
            name
            url
            description
          }
        }
      }
    }
    file(relativePath: { eq: "icons/bg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
